<template>
  <div class="mask">
    <div class="topContainer">
      <div class="top">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-form-item label="电站名称">
            <el-input
              v-model="form.powerStationName"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input
              v-model="form.companyName"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="resert">重置</el-button>
            <el-button type="primary" @click="search">搜索</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- style="
        display: flex;
        width: calc(100vw - 80px);
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10px 40px 0 40px;
        height: calc(100vh - 140px);
        overflow: auto;
      " -->
    </div>
    <div class="box">
      <div
        class="content"
        v-for="(item, index) in powerStationList"
        :key="index"

      >
        <div class="item">
          <div class="itemTop">
            <div class="itemName">{{ item.powerStationAliasName }}</div>
            <div class="itemUnit">
              <span>{{ item.volume >= 1000 ? item.volume/1000 + 'mw'  : item.volume + 'kw'  }}</span
              >
            </div>
          </div>
          <div class="itemContent">
            <div class="itemContent_left">
              <div class="itemContent_left-item">
                <div>
                  <!-- <span style="color: #2acfa9; font-size: 20px">{{
                    item.monthPowerNum
                  }}</span> -->
                  <span style="color: #d7d7d7; font-size: 17px">{{
                    item.currDayPowerNumCount ?  item.currDayPowerNumCount : 0
                  }} <span>度</span> </span>
                </div>
                <div style="margin-top: 5px">
                  <span style="color: #d7d7d7; font-size: 15px">日发电量</span>
                </div>
              </div>
              <div class="itemContent_left-item">
                <div>
                  <!-- <span style="color: #2acfa9; font-size: 20px">{{
                    item.currDayPowerNumCount
                  }}</span> -->
                  <span style="color: #2acfa9; font-size: 17px">{{
                    item.totalPowerNumCount ? item.totalPowerNumCount : 0
                  }}  <span>度</span> </span>
                </div>
                <div style="margin-top: 5px">
                  <span style="color: #d7d7d7; font-size: 15px">累计发电量</span>
                </div>
              </div>
            </div>
            <div class="itemContent_right">
              <el-progress
                type="circle"
                :percentage="item.Volume"
                color="#10596d"
                v-if="item.Volume == 0"
                :width="60"
              ></el-progress>
              <el-progress
                v-else
                type="circle"
                :percentage="100"
                color="#00b2e6"
                width="100"
              ></el-progress>
              <div class="itemContent_right_title">消缺率</div>
              <div class="itemContent_right_content">
                待处理缺陷
                <span style="color: #d9001b; font-size: 24px; font-weight: 600"
                  >0</span
                >
                个
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      powerStationList: [],
      userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
      queryInfo: {
        condition: {},
        count: 0,
        currPage: 0,
        pageCount: 0,
        pageSize: 0,
      },
      form: {
        powerStationName: "",
        deptName: "",
      },
    };
  },
  mounted() {
    this.getPowerStation();
  },
  methods: {
    async getPowerStation() {
      this.queryInfo.condition.userCode = this.userCode;
      const res = await this.$http.post(
        "/powerStation/userStationList",
        this.queryInfo
      );
      if (res.data.code === 200) {
        this.powerStationList = res.data.data.data;

      } else {
        this.$message.error(res.data);
      }
    },
    search() {
      this.getPowerStation();
    },
    resert() {
      this.form = {
        powerStationName: "",
        deptName: "",
      };
      this.getPowerStation();
    },
    displayMask(item) {
      localStorage.setItem("powerStationCode", item.powerStationCode);
      this.$router.push(
        `/analysis/analysis/discrete?powerStationCode=${item.powerStationCode}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mask {
  background-color: #223f6c;
  min-height: calc(100vh - 85px);
  padding: 10px;
  width: 100%;
}
.box {
  width: 1600px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;
  box-sizing: border-box;
  // justify-content: space-between;
  .content {
    width: 24% !important;
    // background-color: #fff;
    margin: 10px 4px 0;

    .itemTop {
      display: flex;
      color: #fff;
      height: 30px;
      line-height: 30px;
      justify-content: space-between;
      padding: 0 10px;
      box-sizing: border-box;
      background-color: #00b2e6;
      // font-size: 18px;
      .itemName {
        //溢出
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .itemContent {
      display: flex;
      padding: 10px 20px;
      box-sizing: border-box;
      background-color: #14304c;
      justify-content: space-around;
      color: #8c96a7;
      // height: 300px;
      // font-size: 18px;
      .itemContent_left {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      }
    }
  }
}
.top {
  padding-top: 20px;
  box-sizing: border-box;
  text-align: end;
}

::v-deep .el-form-item__label {
  color: #fff;
}
::v-deep .el-input__inner {
  background-color: #223f6c !important;
  color: #fff !important;
}
.itemContent_right {
  text-align: center;
}
.itemContent_right_title {
  margin-left: 30px;
  margin-top: 5px;
}

::v-deep .el-progress-circle {
  width: 100px !important;
  height: 100px!important;
}
</style>
