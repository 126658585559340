import { render, staticRenderFns } from "./ProduceElectricity.vue?vue&type=template&id=6a1c241e&scoped=true"
import script from "./ProduceElectricity.vue?vue&type=script&lang=js"
export * from "./ProduceElectricity.vue?vue&type=script&lang=js"
import style0 from "./ProduceElectricity.vue?vue&type=style&index=0&id=6a1c241e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a1c241e",
  null
  
)

component.options.__file = "ProduceElectricity.vue"
export default component.exports